import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FloatingLabel,
  InputGroup,
  Row,
  Col,
  Toast,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { data } from "jquery";
import axios from "axios";
import dataCabang from "../assets/dataCabang";
import AlertComponent from "../components/alert";
import LoadingScreen from "../components/loadingScreen";
import PasswordField from "../components/passwordField";
import { PasswordChecker } from 'react-password-strengthbar-ui';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function App() {
  const navigate = useNavigate();
  const [dataModel, setDataModel] = useState([]);

  const [resultEmailFound, setResultEmailFound] = useState();
  const [feedback, setFeedback] = useState("is-invalid"); // is-invalid or is-valid
  const [typefeedback, setTypefeedback] = useState(""); // valid or invalid from check in database.
  const [isProcessing, setIsProcessing] = useState(false);
  const [ShowAlert, setShowAlert] = useState(true);
  const [msgAlert, setMsgAlert] = useState("");
  const [bgVariantAlert, setBgVariantAlert] = useState("warning");

  const [focused, setFocused] = useState(false);
  const [statusAkun, setStatusAkun] = useState(0);

  const [passwordShown, setPasswordShown] = useState(false);
  const eye = passwordShown ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash}/>

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // const [mode, setMode] = useState("form");
  // const [update, setUpdate] = useState(false);
  // const [search, setSearch] = useState("");

  const [DataForm, setDataForm] = useState({
    EMAIL: "",
    PASS: "",
    CABANG: "",
    IDCABANG: "",
    IDPENGGUNA: "",
  });

  const pageTo = () => {
    navigate("/", { replace: true });
  };

  const toastInfo = () => {
    toast.success("Message : " + sessionStorage.getItem("Message", true), {
      toastId: "custom-id-yes",
    });
  };

  // fitur save data registration
  const _registration = async (req) => {
    console.log(req);
    let url = "Login/regist";
    var config = {
      method: "POST",
      url: url,
      headers: { "Content-Type": "multipart/form-data" },
      data: req,
    };
    await axios(config)
      .then(function (res) {
        // console.log(res);

        setIsProcessing(true);
        sessionStorage.setItem(
          "Message", "Okay Successfuly!"
        );
        setBgVariantAlert("success");
        setTimeout(() => {
          setIsProcessing(false);
          navigate("/", { replace: true }); 
        }, 800);
      })
      .catch(function (error) {
        // toast("Status : " + error);
        sessionStorage.setItem(
          "msgAlert", "Error : " + error
        );
        setMsgAlert(sessionStorage.getItem("msgAlert"));
        setBgVariantAlert("warning");
        setShowAlert(true);
        console.log(error);
      });
  };

  // check email telah terdaftar di app absensi apa belum?
  const _checkEmailkDBAbsensi = async (req) => {
    // console.log(req.EMAIL);
    let url =
      "Validate/CheckEmailAbsensi?Email=" +
      req.EMAIL +
      "";
    var config = {
      method: "GET",
      url: url,
      headers: { "Content-Type": "multipart/form-data" },
    };
    await axios(config).then(function (res) {
      console.log(res.data);
      setStatusAkun(res.data.status);
      // console.log(res.data.status);

      if (res.data.status == 200) {
        // console.log(res.data);
        sessionStorage.setItem(
          "msgAlert",
          res.data.message + " di App Absensi. silahkan kembali login!"
        );
        setMsgAlert(sessionStorage.getItem("msgAlert"));
        setBgVariantAlert("info");
        setShowAlert(true);
      } else {
        sessionStorage.setItem(
          "msgAlert",
          res.data.message + " di App Absensi. silahkan registrasi disini!"
        );
        setMsgAlert(sessionStorage.getItem("msgAlert"));
        setBgVariantAlert("warning");
        setShowAlert(true);
      }
    });
  };

  // fitur check email terdaftar.
  const _checkDataEmail_staf = async (req) => {
    let url =
      "Validate/CheckCabangByEmail?Email=" +
      req.EMAIL;
    var config = {
      method: "GET",
      url: url,
      headers: { "Content-Type": "multipart/form-data" },
      data: dataModel,
    };
    await axios(config)
      .then(function (res) {
        // console.log(res.data.status);
        if (res.data.status == 200) {
          let R = res.data;
          setDataForm({
            CABANG: R.cabang,
            IDCABANG: R.idcabang,
            IDPENGGUNA: R.idpengguna,
            EMAIL: DataForm.EMAIL,
            PASS: DataForm.PASS,
          });

          if (R.typefeedback == "invalid") {
            // console.log("Email tidak terdaftar dalam data kariawan kami!");
            sessionStorage.setItem(
              "msgAlert",
              "Akun " + res.data.message + " Silahkan koreksi Email anda!"
            );
            setMsgAlert(sessionStorage.getItem("msgAlert"));
            setBgVariantAlert("danger");
            setShowAlert(true);
          } else {
            // console.log("email valid (terdaftar sebagai staf kami!)");
            _checkEmailkDBAbsensi(DataForm);
          }

          // set message validation
          setResultEmailFound(R.message);
          setFeedback(R.feedback);
          setTypefeedback(R.typefeedback);
          // set data to model.
        } else {
          setResultEmailFound("Masukkan Email staf terdaftar di kantor anda.");
          setFeedback(res.data.feedback);
          setTypefeedback(res.data.typefeedback);
        }
      })
      .catch(function (error) {
        // toast("Status : " + error);
        console.log(error);
      });
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => {
    setIsProcessing(true);
    setTimeout(() => {
      setFocused(false);
      setFeedback(feedback);
      _checkDataEmail_staf(DataForm);
      setIsProcessing(false);
    }, 1500);
  };

  function setData(e) {
    setDataForm({
      ...DataForm,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    sessionStorage.setItem(
      "msgAlert",
      "Masukkan Email yang Telah Terdaftar Sebagai Staf Dikantor Anda!"
    );
    setMsgAlert(sessionStorage.getItem("msgAlert"));
    setBgVariantAlert("primary");

    // console.log(feedback);
    // console.log(typefeedback);
    // console.log("akun "+statusAkun+". ( true/false = terdaftar/belumter daftar )");
  }, [focused]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 px-0">
          <div className="bg-default bg-gradient rounded-bottom p-4 py-5">
            <p className="h5 text-center text-white py-5 lt-spacing">
              Aplikasi Absensi
            </p>
          </div>
        </div>

        <div
          data-cy="form-login"
          className="col-12 px-0 z-index-1 position-absolute mt-11"
        >
          <div className="bg-white bg-gradient rounded-4 shadow p-3 py-3 mx-4 mb-4">
            <p className="h6 text-center text-default py-4 mt-0 lt-spacing">
              Buat Akun
            </p>

            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <FloatingLabel
                className="mt-4"
                controlId="floatingInputGrid"
                label="Email address"
              >
                <Form.Control
                  type="text"
                  value={DataForm.EMAIL}
                  onChange={setData}
                  name={"EMAIL"}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  className={feedback}
                />
                {isProcessing ? "Tunggu Checking selesai..." : null}
                <Form.Control.Feedback type={typefeedback}>
                  {resultEmailFound}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <FloatingLabel
                className="mt-4"
                controlId="floatingInputGrid"
                label="Cabang"
              >
                <Form.Control
                  type="text"
                  value={DataForm.CABANG}
                  onChange={setData}
                  name={"CABANG"}
                  className={DataForm.CABANG != "" ? "is-valid" : null}
                  readOnly
                />
              </FloatingLabel>
            </Form.Group>

            <PasswordField
              dataRecord={{
                data: DataForm.PASS,
                onChange: setData,
                name: "PASS",
              }}
            />
            <PasswordChecker password={DataForm.PASS} />

            {/* Alert info */}
            {ShowAlert ? (
              <AlertComponent
                Recordmaterial={{
                  message: msgAlert,
                  bgVariant: bgVariantAlert,
                }}
              />
            ) : null}

            <div className="text-center mt-4 d-grid gap-2">
              {feedback == "is-invalid" ? (
                statusAkun === 404 && typefeedback == "invalid" ? null : (
                  <Button
                    variant="secondary text-white rounded-3 py-3"
                    disabled
                    size="sm"
                  >
                    {" "}
                    Buat Akun{" "}
                  </Button>
                )
              ) : statusAkun === 200 && typefeedback == "valid" ? null : (
                <Button
                  onClick={() => _registration(DataForm)}
                  variant="default text-white rounded-3 py-3"
                  size="sm"
                >
                  {" "}
                  Buat Akun{" "}
                </Button>
              )}
            </div>

            <div className="mt-0 d-grid gap-0 fs-6 pt-2">
              <a
                onClick={pageTo}
                className="fst-italic text-decoration-none link-info"
              >
                Kembali login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
