import React, { Component, StrictMode } from "react";
import { BrowserRouter, Router, Route, Routes, Link } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css";

// component App
import App from "./App";
import LoginAkun from "./activity/LoginAkun";
import BuatAkun from "./activity/BuatAkun";
import HankoAuth from "./activity/HankoAuth"; // login with metode passkey

import Dashboard from "./activity/Dashboard";
import RiwayatAbsensi from "./activity/RiwayatAbsensi";
import ScanQr from "./activity/ScanQr";
import Tesloadingscreen from "./activity/LoadingScreen";
import CheckHelper from "./components/checkHelper";

import ProfilSaya from "./activity/ProfilSaya";
import EditProfilSaya from "./activity/EditProfilSaya";
import Testscanqr from "./activity/TesQr";
// import Tes from "./Tes";
// import TestImei from "./TestEmai";
// import Testscanqr02 from "./activity/React-qr-scanner";

import reportWebVitals from "./reportWebVitals";

import axios from "axios";
axios.defaults.baseURL = "https://apiabsensi.whusnet.com/api";
axios.defaults.headers.post["Content-Type"] = "application/json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginAkun />} />
        <Route path="/BuatAkun" element={<BuatAkun />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/CheckHelper" element={<CheckHelper />} />
        <Route path="/RiwayatAbsensi" element={<RiwayatAbsensi />} />
        <Route path="/ProfilSaya" element={<ProfilSaya />} />
        <Route path="/EditProfilSaya" element={<EditProfilSaya />} />
        <Route path="/ScanQr" element={<ScanQr />} />
        <Route path="/tesloading" element={<Tesloadingscreen />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
reportWebVitals();
