import React  from "react";
import {
  Container,
  Modal,
  Row,
} from "react-bootstrap";
import "../assets/css/loading.css";
import logo from "../assets/icons/upik_icon.png";

export default function app() { 
  // setTimeout(() => {
  // }, 1000);
  //   let R = props.recordMaterial;
  //   console.log(R.title);
  return (
    <Container>
      <Row className="justify-content-md-center align-items-center">
        <Modal show={true}>
          <Modal.Body className="d-flex justify-content-center">
            <div className="loader">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
              <div className="logo">
                <img
                  src={logo}
                  alt=""
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Row>
    </Container>
  );
}
