import React from "react";
import "../assets/css/loading.css";
import logo from '../assets/icons/whusnet_icon.png';
// import logo from '../assets/icons/upik_icon.png';

export default function app() {
//   let R = props.recordMaterial;
//   console.log(R.title);
    return (
    <div className="loader">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <div className="logo">
            <img src={logo} alt="" />
        </div>
    </div>
    )
}